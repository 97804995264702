import axios from 'axios'
import _get from 'lodash/get'

export const processAdjustment = async (args: any) => {
  let {handleError, sessionKeys, setSubmitted, MerchantID, mode} = args
  try {
    let postObj = {
      method: 'POST',
      url: `${sessionKeys.paygate_url}/api/session/adjust`,
      headers: {
        'x-api-key': `${sessionKeys.session1_token}`,
      },
      data: args,
    }

    let result = await axios(postObj)

    setSubmitted(_get(result, 'ref', false))
    //WE HAVE RESULT - SEND TOKENS TO PARENT

    window.parent.postMessage(
      {
        merchant: MerchantID,
        message: 'PAYRESULT',
        mode,
        ref: _get(result, 'data.providerResponse.providerRef', '???'),
        amount: _get(result, 'data.providerResponse.authorisedAmount', '???'),
      },
      '*'
    )
  } catch (e) {
    window.parent.postMessage(
      {
        error: true,
        message: 'PAYRESULT',
        details: _get(e, 'message', 'Failed!'),
        mode,
      },
      '*'
    )

    return handleError(e)
  }
}
