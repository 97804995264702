import React, {useState, useMemo, useRef, useEffect, useLayoutEffect, useCallback} from 'react'
import {
  Flex,
  Box,
  HStack,
  Spacer,
  Grid,
  GridItem,
  Text,
  Input,
  InputGroup,
  InputLeftAddon,
  Button,
  Spinner,
} from '@chakra-ui/react'
import {
  FaCreditCard,
  FaCcAmex,
  FaCcDinersClub,
  FaCcMastercard,
  FaCcVisa,
  FaClock,
  FaCheck,
  FaLock,
  FaUser,
  FaDollarSign,
  FaEye,
  FaEdit,
} from 'react-icons/fa'
import {IMaskInput} from 'react-imask'
import {useForm} from 'react-hook-form'
import luhn from 'luhn'
import numeral from 'numeral'
import moment from 'moment'
import _startCase from 'lodash/startCase'
import _toNumber from 'lodash/toNumber'
import _get from 'lodash/get'
import { create } from 'zustand'

import {Form, InputControl} from '../../Library'
import {processAdjustment, processPayment, processClaim, processRefund, getCardDetails} from '../../PayGate'
import {CardErrors} from './CardErrors'
import {CardResultNotice} from './CardResultNotice'
import {ISessionKeys} from '../../Interfaces'

export enum ICardMethod {
  'PAYMENT',
  'REFUND',
  'PREAUTH',
  'CLAIM',
  'VIEW',
}

interface FormInputTypes {
  card_number: string
  expiry_date: string
  cvc: string
  cardholder: string
  amount: string
  card_token?: string
  cvv_token?: string
  reason?: string
  id?: string //ID Is set for Claims
  reference?: string //Added to hold bookling number
}

enum CardType {
  default = 'CARD',
  amex = 'AMEX',
  visa = 'VISA',
  mastercard = 'MCARD',
  diners = 'DINERS',
}

export const CardEntry = () => {
  // data
  const [cardLogo, setCardLogo] = useState<JSX.Element | null>(null)
  const [TransactionType, setTransactionType] = useState('MOTO')
  const [cardType, setCardType] = useState<CardType>(CardType.default)
  const [tranID, setTranID] = useState('')
  const [MerchantID, setMerchantID] = useState('')
  const [CheckInDate, setCheckInDate] = useState('')
  const [CheckOutDate, setCheckOutDate] = useState('')
  const [sessionKeys, setSessionKeys] = useState<ISessionKeys>({
    session1_token: '',
    session2_token: '',
    session1_expiry: '',
    session2_expiry: '',
    paygate_url: '',
    vault_url: '',
    vault_rule: '',
    provider_base_url: '',
    expiry: 0,
  })
  const [amount, setAmount] = useState('')
  const [cardToken, setCardToken] = useState('')
  const [cvcToken, setCvcToken] = useState('')

  // styling and UI
  const [small, setSmall] = useState<boolean>(false)
  const [size, setSize] = useState('sm')
  const [fontSize, setFontSize] = useState(18)
  const [waitMsg, setWaitMsg] = useState('');
  const [title, setTitle] = useState('')
  const [hasHeader, setHasHeader] = useState(true)
  const [labelProps, setLabelProps] = useState({color: 'white'})
  const [headerProps, setHeaderProps] = useState({color: 'white'})
  const [label, setLabel] = useState('Pay')
  const [showResult, setShowResult] = useState(true)
  const [showReference, setShowReference] = useState(false)
  const [hasButton, setHasButton] = useState(false)
  const [customTitle, setCustomTitle] = useState(false)
  const [customLabel, setCustomLabel] = useState(false)
  const [message, setMessage] = useState('')
  const [messageStyle, setMessageStyle] = useState({})
  const [resize, setResize] = useState(false)
  const [triggerResize, setTriggerResize] = useState(false)
  const [containerStyle, setContainerStyle] = useState({
    backgroundColor: 'transparent',
  })
  const [cardStyle, setCardStyle] = useState<any>({
    background: 'linear-gradient(253.52deg, #004365 0.56%, #006CA3 100.56%)',
  })

  // functionality
  const [allowCard, setAllowCard] = useState(false) 
      // allowCard is if current card type is allowed, allowCardType is the string for types allowed for the widget's current setup.
  const [allowCardType, setAllowCardType] = useState('') //Blank is all Types
  const [allowSubmit, setAllowSubmit] = useState(false)
  const [mode, setMode] = useState('')
  const [allowAmount, setAllowAmount] = useState(false)
  const [sneak, setSneak] = useState({
    raw_card: '',
    raw_cvc: '',
    fallback_card: '',
    fallback_cvc: '',
  })
  const [sneakTimeout, setSneakTimeout] = useState(5000) //default sneak is 5 seconds
  const [submitted, setSubmitted] = useState('')

  const ref = useRef<HTMLInputElement>(null)
  const submitRef = useRef<HTMLButtonElement>(null)

  const form = useForm<FormInputTypes>({
    defaultValues: {
      card_number: '',
      expiry_date: '',
      cvc: '',
      cardholder: '',
      reason: '',
      reference: '',
    },
  })
  const {setValue, handleSubmit, clearErrors, watch, setFocus, formState: { errors }, register} = form
  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors]);

  const card_number = watch('card_number')
  const cvc = watch('cvc')
  const expiry_date = watch('expiry_date')
  const cardholder = watch('cardholder')
  const reason = watch('reason')
  const reference = watch('reference')

  const hasAllValues = !!card_number && !!cvc && !!expiry_date && !!cardholder;

  const isSubmitDisabled = !hasButton && ((mode !== 'CAPTURE' && amount === '') || !!submitted.trim() || !allowSubmit || !hasAllValues || hasErrors);

  const showCardDetails = useCallback(async () => {
    const [cardNo, cvcNo] = await getCardDetails({
      sessionKeys,
      cardToken,
      cvcToken,
    })
    setSneak({
      raw_card: cardNo,
      raw_cvc: cvcNo,
      fallback_card: card_number,
      fallback_cvc: cvc,
    })
  }, [sessionKeys, cardToken, cvcToken, card_number, cvc])

  const checkCardReader = useCallback((val: string) => {
    const arr = val.split('^')
    if (arr.length >= 3) {
      const card = arr[0].replace('%B', '')
      const cardholder = arr[1].replace('/', ' ').replace('.', '').trim()
      const str = arr[2].replace('/', '').trim().substring(0, 4)
      const expiry = `${str.substring(2, 4)}/${str.substring(0, 2)}`
      setValue('card_number', card)
      setValue('cardholder', cardholder)
      setValue('expiry_date', expiry)
    }
  }, [setValue]);

  const handleError = useCallback((e: any) => {
    //Return Error Response to calling App
    console.log('## Widget@WIDGET - Hande Error', e)
    const errObj = _get(e, 'response.data', e)
    const err: any = {
      message: 'PAYRESULT',
      error: _get(errObj, 'error', true),
      success: _get(errObj, 'success', false),
      errorMessage: _get(errObj, 'message', '???'),
      detail: _get(errObj.detail, ''),
      status: _get(errObj, 'status', '???'),
      statusText: _get(errObj, 'statusText', ''),
      identity: _get(errObj, 'identity', ''),
      merchant: MerchantID,
    }
    console.log('## Widget@WIDGET POST ERROR', err)
    window.parent.postMessage(err, '*')
  }, [MerchantID])

  const onSubmit = useCallback(async () => {
    if (hasButton) {
      useCardEntryStore.getState().setSubmitting(true);
    }
    if (mode === 'PAYMENT' || mode === 'PREAUTH' || mode === 'CAPTURE') {
      console.log('## Widget@@Widget - CALL Process Payment')
      await processPayment({
        handleError,
        sessionKeys,
        expiry_date,
        card_number,
        cardholder,
        amount,
        cvc,
        mode,
        MerchantID,
        setSubmitted,
        checkin_date: CheckInDate,
        checkout_date: CheckOutDate,
        cardType,
        reference,
        reason,
        TransactionType,
      })
      // should use single function?
    } else if (mode === 'CLAIM') {
      await processClaim({
        tranID,
        reason,
        handleError,
        sessionKeys,
        amount,
        mode,
        MerchantID,
        reference,
        setSubmitted,
      })
    } else if (mode === 'ADJUST') {
      await processAdjustment({
        tranID,
        reason,
        handleError,
        sessionKeys,
        amount,
        mode,
        MerchantID,
        reference,
        setSubmitted,
      })
    } else if (mode === 'REFUND') {
      processRefund({
        tranID,
        reason,
        handleError,
        sessionKeys,
        amount,
        mode,
        MerchantID,
        reference,
        setSubmitted,
      })
    }
    useCardEntryStore.getState().setSubmitting(false);
  }, [tranID, reason, handleError, sessionKeys, amount, mode, MerchantID, reference, expiry_date, card_number, cardholder, cvc, CheckInDate, CheckOutDate, cardType, TransactionType, hasButton])

  const receiveMessage = useCallback((event: any) => {
    if (event && event.data) {
      let obj = event.data
      if (obj && !obj.type) {
        //console.log('## Widget - Received', obj)

        //let merchID = _get(obj, 'MerchantID')
        if (obj && obj.SessionKeys) {
          setSessionKeys(obj.SessionKeys)
        }
        if (obj && obj.CheckInDate) {
          setCheckInDate(obj.CheckInDate)
        }
        if (obj && obj.CheckOutDate) {
          setCheckOutDate(obj.CheckOutDate)
        }
        if (obj && obj.Resize) setResize(true)
        if (obj && obj.Card) {
          setValue('card_number', obj.Card.card_number)
          setValue('cvc', obj.Card.cvc)
          setValue('expiry_date', obj.Card.expiry_date)
          setValue('cardholder', obj.Card.cardholder)
          setValue('reason', obj.Card.reason)
          setValue('reference', obj.Card.reference) //Added so booking number can be passed

          setTranID(obj.Card.id)
          setCardToken(obj.Card.card_token)
          setCvcToken(obj.Card.cvc_token)

          if (obj.Card && typeof obj.Card.amount !== 'undefined')
            if (obj.Card.amount === '') setAmount('')
            else setAmount(numeral(obj.Card.amount).format('0,0.00'))
        }
        if (obj && obj.Focus) {
          setFocus(obj.Focus)
        }
        if (obj && obj.Amount) setAmount(numeral(obj.Amount).format('0,0.00'))
        if (obj && obj.Mode) {
          setMode(obj.Mode)
        }
        if (obj && obj.Title) {
          setCustomTitle(true)
          setTitle(obj.Title)
        }
        if (obj && obj.Label) {
          setCustomLabel(obj.Label)
          setLabel(obj.Label)
        }

        if (obj && (obj.Message || obj.Message === '')) {
          setMessage(obj.Message)
        }
        if (obj && obj.MessageStyle) setMessageStyle(obj.MessageStyle)

        if (obj && typeof obj.AllowAmount === 'boolean') {
          setAllowAmount(obj.AllowAmount)
        }
        //2022-10-04 ADDED TITLE FONT SIZE
        if (obj && obj.TitleFontSize) {
          setFontSize(obj.TitleFontSize)
        }
        //2022-10-04 ADDED SETTIMG XS,SM,MD,LG,XG FOR SIZE
        if (obj && obj.Size) {
          setSize(obj.Size)
        }
        //2022-11-07 ADD AllowCardTypr (as an enum 1.Mastercard, 2. Bankcard, 3.Amex, 4 Visa, 5. Discover, 6 Diners, 7 JCB, 8 Other)
        //Note Only Mastercard, Amex, Visa and Diners supported in V1
        if (obj && obj.AllowCardType) {
          setAllowCardType(obj.AllowCardType.trim())
        }

        //if (merchID) setMerchantID(merchID)
        if (obj && obj.MerchantID) {
          setMerchantID(obj.MerchantID)
        }
        if (obj && obj.HasHeader) {
          setHasHeader(obj.HasHeader !== 'false')
        }
        if (obj && obj.HasButton) {
          setHasButton(obj.HasButton !== 'false')
        }
        if (obj && obj.LabelProps) {
          setLabelProps(obj.LabelProps)
        }
        if (obj && obj.Submit) {
          if (obj.Submit === 'true' || obj.Submit === true) {
            console.log('## Widget Submit Button Force Click')
            handleSubmit(onSubmit)();
            //submitRef.current?.click() //document.getElementById('PAYGATE_SUBMIT')?.click()
          }
        }
        if (obj && obj.ShowResult) {
          setShowResult(obj.ShowResult !== 'false')
        }
        if (obj && obj.ShowReference) {
          setShowReference(obj.ShowReference !== 'false')
        }
        if (obj && obj.HeaderProps) {
          setHeaderProps(obj.HeaderProps)
        }
        if (obj && obj.TransactionType) {
          setTransactionType(obj.TransactionType);
        }

        if (obj?.Style) {
          setContainerStyle(obj.Style)
        }
        if (obj?.CardStyle) {
          setCardStyle({ style: obj.CardStyle })
        }
      }
    }
  }, [setValue, setFocus, handleSubmit, onSubmit]);

  //SEND READY BACK TO PARENT WHEN DOM INITIALISED
  useLayoutEffect(() => {
    if (ref && ref.current) {
      window.parent.postMessage(
        {
          message: 'READY',
          ready: true,
        },
        '*'
      )
      setFocus('card_number')
    }
  }, [ref, setFocus])

  // change wait message depending on what's missing
  useEffect(() => {
    if (!MerchantID || !mode) {
      let msg = ''
      msg = 'Waiting on '
      if (!MerchantID) msg += `Merchant ID ${!mode ? ' and ' : ''}`
      if (!mode) msg += `Payment Type`
      setWaitMsg(msg);
    } else {
      setWaitMsg('');
    }
  }, [MerchantID, mode]);

  // resize when changing mode
  useEffect(() => {
    setResize(true)
  }, [mode])

  // resize if there are errors
  useEffect(() => {
    if (hasErrors) {
      setResize(true);
    }
  }, [hasErrors])

  useEffect(() => {
    switch (size) {
      case 'xs':
        setFontSize(16)
        setTimeout(() => setResize(true), 100)

        break
      case 'sm':
        setFontSize(18)
        setTimeout(() => setResize(true), 100)
        break
      case 'md':
        setFontSize(20)
        setTimeout(() => setResize(true), 100)
        break
      case 'lg':
        setFontSize(22)
        setTimeout(() => setResize(true), 100)
        break
      case 'xl':
        setFontSize(24)
        setTimeout(() => setResize(true), 4000)
        break
    }
  }, [size])

  useEffect(() => {
    switch (mode) {
      case 'REFUND':
        if (!customTitle) setTitle('Credit Card Refund')
        if (!customLabel) setLabel('Refund to Card')
        break
      case 'PREAUTH':
        if (!customTitle) setTitle('Pre Authorisation')
        if (!customLabel) setLabel('Pre Authorise')
        break
      case 'ADJUST':
        if (!customTitle) setTitle('Authorisation Adjustment')
        if (!customLabel) setLabel('Process Adjustment')
        //form.setValue('reason', '')
        break
      case 'CLAIM':
        if (!customTitle) setTitle('Pre-Auth Claim')
        if (!customLabel) setLabel('Claim')
        //form.setValue('reason', '')
        break
      case 'PAYMENT':
        if (!customTitle) setTitle('Credit Card Payment')
        if (!customLabel) setLabel('Pay')
        break

      case 'VIEW':
        if (!customTitle) setTitle('View Credit Card')
        if (!customLabel) setLabel('Sneak Peak Card Number and CVV')
        setSneakTimeout(5000)
        break
      case 'SHOW':
        if (!customTitle) setTitle('View Credit Card')
        if (!customLabel) setHasButton(false)
        setSneakTimeout(300000) //Show can be active for 5 minutes maximum
        showCardDetails()
        break

      case 'CAPTURE':
      default:
        if (!customTitle) setTitle('')
        if (!customLabel) setLabel('Save Card')
        break
    }
  }, [mode, customTitle, customLabel, form, showCardDetails])

  // RESIZE FOR CLAIM (EXTRA REASON FIELD)
  useEffect(() => {
    if (mode === 'CLAIM') setTriggerResize(true)
  }, [mode])

  // RESIZE IF ERRORS DISPLAYED
  useEffect(() => {
    if (errors.reason) {
      setTimeout(() => {
        setTriggerResize(true)
      }, 0)
    }
  }, [errors.reason])

  useEffect(() => {
    if (ref.current) {
      let dim = ref.current.getBoundingClientRect()
      setSmall(dim.width < 400)
      if (resize) setResize(false)
      if (triggerResize) setTriggerResize(false)
      window.parent.postMessage(
        {
          message: 'RESIZE',
          resize: true,
          dimensions: dim,
        },
        '*'
      )
    }
  }, [ref, resize, triggerResize])

  useEffect(() => {
    let prefix = String(card_number).substring(0, 1)
    if (prefix === '3') prefix = String(card_number).substring(0, 2)

    let allowCard = false
    let cardType = CardType.default
    let logo = <FaCreditCard size={fontSize + 5} color={allowCard ? 'black' : 'red'} />

    switch (prefix) {
      case '30':
      case '36':
      case '38':
        allowCard = allowCardType === '' || allowCardType.indexOf('6') >= 0
        if (allowCard) {
          cardType = CardType.diners
          logo = <FaCcDinersClub size={fontSize + 5} color={allowCard ? 'black' : 'red'} />
        }
        break
      case '34':
      case '37':
        allowCard = allowCardType === '' || allowCardType.indexOf('3') >= 0
        if (allowCard) {
          cardType = CardType.amex
          logo = <FaCcAmex size={fontSize + 5} color={allowCard ? 'black' : 'red'} />
        }
        break
      case '4':
        allowCard = allowCardType === '' || allowCardType.indexOf('4') >= 0
        if (allowCard) {
          cardType = CardType.visa
          logo = <FaCcVisa size={fontSize + 5} color={allowCard ? 'black' : 'red'} />
        }
        break
      case '2':
      case '5':
        allowCard = allowCardType === '' || allowCardType.indexOf('1') >= 0
        if (allowCard) {
          cardType = CardType.mastercard
          logo = <FaCcMastercard size={fontSize + 5} color={allowCard ? 'black' : 'red'} />
        }
        break
      default:
        cardType = CardType.default
        allowCard = allowCardType === ''
        logo = <FaCreditCard size={fontSize + 5} color={allowCard ? 'black' : 'red'} />
    }
    setCardType(cardType)
    setCardLogo(logo)
    setAllowSubmit(allowCard)
    setAllowCard(allowCard)
  }, [card_number, fontSize, allowCardType])

  useEffect(() => {
    if (sneak && sneak.raw_card) {
      setValue('card_number', sneak.raw_card)
      setValue('cvc', sneak.raw_cvc)
      setTimeout(() => {
        setValue('card_number', sneak.fallback_card)
        setValue('cvc', sneak.fallback_cvc)
        setSneak({
          raw_card: '',
          raw_cvc: '',
          fallback_card: '',
          fallback_cvc: '',
        })
      }, sneakTimeout)
    }
  }, [sneak, sneakTimeout, setValue])

  useEffect(() => {
    window.addEventListener('resize', () => setResize(true))
    window.addEventListener('message', receiveMessage, false)
    return () => {
      window.removeEventListener('resize', () => setResize(true))
      window.removeEventListener('message', receiveMessage)
    }
  }, [receiveMessage])

  useEffect(() => {
    return () => {
      clearErrors();
    };
  }, [clearErrors]);

  // If embed property s true then contrain within container otherwise it will be iframe - so go to 100vh
  return (
    <Box bg="transparent" m={0} p={0} {...containerStyle} style={{margin: '0 auto'}} overflow="hidden">
      <Box
        id="card-entry-container"
        ref={ref}
        p={3}
        borderRadius="8px"
        bg="gray.100"
        h="100%"
        minHeight="350px"
        overflow="hidden"
        {...cardStyle}
      >
        <>
          <Form id="card-entry-form" onSubmit={handleSubmit(onSubmit)} fontFamily={'Public Sans'}>
            <fieldset disabled={submitted ? true : false || mode === 'VIEW'}>
              {hasHeader && (
                <Grid templateColumns={small ? '1fr' : '2fr 1fr'} gap="8px">
                  <GridItem>
                    <Text fontSize={fontSize + 6} fontWeight={700} textAlign="left" {...headerProps}>
                      {title}
                    </Text>
                  </GridItem>
                  <GridItem>
                    <HStack>
                      {!small && <Spacer />}
                      {(allowCardType === '' || allowCardType.indexOf('3') >= 0) && (
                        <FaCcAmex size={fontSize + 10} {...headerProps} />
                      )}
                      {(allowCardType === '' || allowCardType.indexOf('6') >= 0) && (
                        <FaCcDinersClub size={fontSize + 10} {...headerProps} />
                       )}
                       {(allowCardType === '' || allowCardType.indexOf('1') >= 0) && (
                         <FaCcMastercard size={fontSize + 10} {...headerProps} />
                       )}
                       {(allowCardType === '' || allowCardType.indexOf('4') >= 0) && (
                         <FaCcVisa size={fontSize + 10} {...headerProps} />
                       )}

                    </HStack>
                  </GridItem>
                </Grid>
              )}
              <CardErrors errors={errors} setResize={setTriggerResize} />
              {!Object.keys(errors).length && (
                <Box h="100%" display={MerchantID && mode ? 'none' : 'unset'}>
                  <Flex
                    id="paygate-client-loading"
                    h="100%"
                    direction="column"
                    p={2}
                    alignItems={'center'}
                    justifyContent="center"
                    minHeight={300}
                  >
                    <Text color="gray.200" fontSize="18px" mb={3}>
                      {waitMsg}
                    </Text>
                    <Flex>
                      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                    </Flex>
                  </Flex>
                </Box>
              )}
              <Box display={MerchantID && mode ? 'unset' : 'none'}>
                <Grid templateColumns={small ? '1fr' : '1fr 1fr'} gap="8px">
                  {hasHeader && <GridItem h={5} colSpan={small ? 1 : 2} />}
                  {message && (
                    <>
                      <GridItem p={2} borderRadius="5px" bg="gray.200" colSpan={small ? 1 : 2} style={messageStyle}>
                        <Text textAlign="center">{message}</Text>
                      </GridItem>
                      <GridItem h={5} colSpan={small ? 1 : 2} />
                    </>
                  )}
                  <GridItem />
                  {mode !== 'CAPTURE' && (
                    <GridItem>
                      <InputControl
                        form={form}
                        id="amount"
                        label="Amount"
                        labelProps={{fontSize: fontSize - 4, textAlign: 'right', ...labelProps}}
                        control={
                          <InputGroup size={size}>
                            <InputLeftAddon children={<FaDollarSign size={fontSize} />} />
                            <Input
                              textAlign={'right'}
                              fontSize={fontSize - 5}
                              value={amount}
                              onChange={e => setAmount(String(e.target.value).replace(/[^0-9.]/g, ''))}
                              onBlur={e =>
                                setAmount(numeral(_toNumber(e.target.value.replace(',', ''))).format('0,0.00'))
                              }
                              disabled={!allowAmount}
                              fontWeight={600}
                            />
                          </InputGroup>
                        }
                      />
                    </GridItem>
                  )}

                  <GridItem colSpan={small ? 1 : 2}>
                    <InputControl
                      form={form}
                      id="card_number"
                      label="Card Number"
                      labelProps={{fontSize: fontSize - 4, ...labelProps}}
                      validation={{
                        required: 'Card Number is Required',
                        validate: (value: any) => {
                          if (!allowCard) return 'Card type is not allowed'
                          if (mode === 'PAYMENT' || mode === 'CAPTURE') return luhn.validate(value) ? null : 'Card number is not valid'
                          else return true
                        },
                      }}
                      control={
                        <InputGroup size={size}>
                          <InputLeftAddon children={cardLogo} />
                          <Input
                            {...register('card_number')}
                            as={IMaskInput}
                            fontSize={fontSize - 5}
                            mask={
                              cardType === CardType.amex
                                ? '0000 0000 0000 000'
                                : cardType === CardType.diners
                                ? '0000 0000 0000 00'
                                : '0000 0000 0000 0000'
                            }
                            onAccept={(value: any) => {
                              setValue('card_number', value)
                            }}
                            size={size}
                            onBlur={() => clearErrors('card_number')}
                            placeholder="Example: 1234 5678 9012 3456"
                          />
                        </InputGroup>
                      }
                    ></InputControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <InputControl
                      form={form}
                      id="expiry_date"
                      label="Expiry MM/YY"
                      labelProps={{fontSize: fontSize - 4, ...labelProps}}
                      validation={{
                        required: 'Expiry Date is Required',
                        validate: (value: any) => {
                          const month = parseInt(String(value).substring(0, 2))
                          const year = parseInt(String(value).substring(3, 5)) + 2000

                          if (month < 1 || month > 12) return 'Invalid Month'
                          const diff = moment().year(year).month(month).diff(moment())
                          const diffInYears = moment.duration(diff).asYears();
                          return diff <= 0 ? 'Date must be in the future' :
                                diffInYears > 10 ? 'Date must be within 10 years' : true;
                        },
                      }}
                      control={
                        <InputGroup size={size}>
                          <InputLeftAddon children={<FaClock size={fontSize} />} />
                          <Input
                            {...register('expiry_date')}
                            as={IMaskInput}
                            fontSize={fontSize - 5}
                            mask="00/00"
                            onAccept={
                              (value: any) => setValue('expiry_date', value)
                            }
                            size={size}
                            onBlur={() => clearErrors('expiry_date')}
                            placeholder="Example: MM/YY"
                          />
                        </InputGroup>
                      }
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <InputControl
                      form={form}
                      id="cvc"
                      label="CVC / CVV"
                      labelProps={{fontSize: fontSize - 4, ...labelProps}}
                      validation={{
                        required: 'CVC is required',
                        minLength: {
                          value: cardType === CardType.amex ? 4 : 3,
                          message: `CVC / CVV Must be ${cardType === CardType.amex ? 4 : 3} characters`,
                        },
                      }}
                      control={
                        <InputGroup size={size}>
                          <InputLeftAddon children={<FaCheck size={fontSize} />} />
                          <Input
                            {...register('cvc')}
                            as={IMaskInput}
                            mask={cardType === CardType.amex ? '0000' : '000'}
                            onAccept={
                              (value: any) => setValue('cvc', value)
                            }
                            onBlur={() => clearErrors('cvc')}
                            size={size}
                            placeholder={cardType === CardType.amex ? 'Example: 6789' : 'Example: 789'}
                          />
                        </InputGroup>
                      }
                    />
                  </GridItem>
                  <GridItem colSpan={small ? 1 : 2}>
                    <InputControl
                      id="cardholder"
                      label="Name on card"
                      labelProps={{fontSize: fontSize - 4, ...labelProps}}
                      form={form}
                      validation={{
                        required: 'Cardholder name is required',
                        minLength: {
                          value: 3,
                          message: 'Name on Card must be at least 3 characters',
                        },
                        maxLength: {
                          value: 100,
                          message: 'Name on Card cannot be longer than 100 characters'
                        },
                      }}
                      control={
                        <InputGroup size={size}>
                          <InputLeftAddon children={<FaUser size={fontSize} />} />
                          <Input
                            {...register('cardholder')}
                            sx={{backgroundColor: 'transparent !important'}}
                            fontSize={fontSize - 5}
                            onChange={e => {
                              checkCardReader(e.target.value)
                              setValue('cardholder', e.target.value)
                            }}
                            size={size}
                            placeholder="Example: Mr J Smith"
                            onBlur={() => clearErrors('cardholder')}
                          />
                        </InputGroup>
                      }
                    ></InputControl>
                  </GridItem>

                  {(mode === 'CLAIM' || mode === 'ADJUST') && (
                    <GridItem colSpan={small ? 1 : 2}>
                      <InputControl
                        id="reason"
                        label={`${_startCase(String(mode).toLowerCase())} Reason`}
                        labelProps={{fontSize: fontSize - 4, ...labelProps}}
                        form={form}
                        validation={{
                          required: 'Reason is required',
                          minLength: {
                            value: 3,
                            message: 'Reason must be at least 3 character',
                          },
                        }}
                        control={
                          <InputGroup size={size}>
                            <InputLeftAddon children={<FaEdit size={fontSize} />} />
                            <Input
                              sx={{backgroundColor: 'transparent !important'}}
                              fontSize={fontSize - 5}
                              onChange={e => {
                                setValue('reason', e.target.value)
                              }}
                              size={size}
                              placeholder={`Reason for Pre-Authorisation ${mode === 'CLAIM' ? 'claim' : 'adjustment'}`}
                            />
                          </InputGroup>
                        }
                      ></InputControl>
                    </GridItem>
                  )}
                  {showReference && (
                    <GridItem colSpan={small ? 1 : 2}>
                      <InputControl
                        id="reference"
                        label="Reference"
                        labelProps={{fontSize: fontSize - 4, ...labelProps}}
                        form={form}
                        control={
                          <InputGroup size={size}>
                            <InputLeftAddon children={<FaEdit size={fontSize} />} />
                            <Input
                              sx={{backgroundColor: 'transparent !important'}}
                              fontSize={fontSize - 5}
                              onChange={e => {
                                checkCardReader(e.target.value)
                                setValue('reference', e.target.value)
                              }}
                              size={size}
                              placeholder="Payment Reference"
                              onBlur={() => clearErrors('reference')}
                            />
                          </InputGroup>
                        }
                      ></InputControl>
                    </GridItem>
                  )}

                  <GridItem mb={2} colSpan={small ? 1 : 2}>
                    <SubmitButton mode={mode} size={size} isSubmitDisabled={isSubmitDisabled} hasButton={hasButton} label={label} submitRef={submitRef} />
                  </GridItem>

                  {submitted && showResult && Object.keys(errors).length > 0 && (
                    <GridItem mb={2} colSpan={small ? 1 : 2}>
                      <CardResultNotice
                        setResize={setTriggerResize}
                        okMessage={`Thank you - this credit card has been succesfully processed - Provider Reference ${submitted}!`}
                        errorMessage={`Cannot submit payment owing to the above errors`}
                      />
                    </GridItem>
                  )}
                </Grid>
              </Box>
            </fieldset>
            {mode === 'VIEW' && (
              <Button
                leftIcon={<FaEye size={18} />}
                disabled={!cardToken || !cvcToken}
                onClick={() => {
                  showCardDetails()
                }}
                w="100%"
                colorScheme="facebook"
                bg="#28282B"
                color="white"
                size={size}
                variant="solid"
              >
                {label}
              </Button>
            )}
          </Form>
        </>
      </Box>
    </Box>
  )
}

interface SubmitButtonProps {
  mode: string;
  hasButton: boolean;
  label: string;
  size: string;
  isSubmitDisabled: boolean;
  submitRef: any;
}
const SubmitButton: React.FC<SubmitButtonProps> = React.memo((props) => {
  const { mode, hasButton, label, size, isSubmitDisabled, submitRef } = props;
  const isSubmitting = useCardEntryStore((state) => state.isSubmitting);

  return (
    <>
      {mode !== 'VIEW' && mode !== 'SHOW' && (
        <Button
          id="PAYGATE_SUBMIT"
          ref={submitRef}
          leftIcon={<FaLock />}
          type="submit"
          w="100%"
          colorScheme="facebook"
          bg="#28282B"
          color="white"
          size={size}
          variant="solid"
          sx={hasButton ? {} : {display: 'none'}}
          disabled={isSubmitDisabled || isSubmitting}
        >
          {label}
        </Button>
      )}
    </>
  )
});

type CardEntryStore = {
  isSubmitting: boolean;
  setSubmitting: (arg0: boolean) => void;
}
const useCardEntryStore = create<CardEntryStore>((set) => ({
  isSubmitting: false,
  setSubmitting: (isSubmitting) => set({ isSubmitting })
}))
