import axios from 'axios'
import {ISessionKeys} from '../Interfaces'

interface RequestObject {
  value: string
  TokenFormat?: ['guid', 'ulid', 'other']
}

//================================================
// GET TOKEN FROM VAULT (TOKEN IS STORED IN VAULT)
//================================================
export const createToken = async (obj: RequestObject, session: ISessionKeys) => {
  const reqObj = {
    method: 'POST',
    url: `${session.vault_url}/tokens`,
    data: {
      TokenFormat: 'guid',
      MaskFormat: 'First6Last4',
      ...obj,
    },
    headers: {
      'X-API-KEY': `${session.session2_token}`,
    },
  }
  return await axios(reqObj)
}
