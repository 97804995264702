import axios from 'axios'
import {createToken} from '../PCIProxy'
import _get from 'lodash/get'

const getExpiry = (expiry: string) => {
  let month = expiry.substring(0, 2)
  let year = '20' + expiry.substring(3, 5)
  return [month, year]
}

const getCardTypeEnum = (cardNo: string) => {
  let prefix = cardNo.substring(0, 1)
  if (prefix === '3') prefix = cardNo.substring(0, 2)
  switch (prefix) {
    case '2':
    case '5':
      return '1'
    case '4':
      return '4'
    case '34':
    case '37':
      return '3'
    case '30':
    case '36':
    case '38':
      return '6'
    default:
      return ''
  }
}

const getExpiryString = (year: string, month: string) => {
  // month is supposed to be month after but months in Date is zero-based
  const date = new Date(parseInt(year), parseInt(month), 1)
  const monthString = (date.getMonth() + 1).toString().padStart(2, '0')

  return `${date.getFullYear()}-${monthString}-01`
}

export const processPayment = async (args: any) => {
  let {
    handleError,
    sessionKeys,
    expiry_date,
    card_number,
    cardholder,
    amount,
    cvc,
    mode,
    MerchantID,
    checkin_date,
    checkout_date,
    setSubmitted,
    reference,
    reason,
    TransactionType = 'MOTO',
  } = args
  if (!reason) reason = 'Guestpoint Transaction'

  //GET EXPIRY MONTH & YEAR
  let [expiry_month, expiry_year] = getExpiry(expiry_date)
  /*
      STEP 1 - GET TOKENS FOR CARD NUMBER AND CVV FROM VAULT
    */
  //additionalconsole.log('## Widget-Process Payment', args)
  let card = String(card_number).replace(/ /g, '')
  console.log('## Widget - processPayment()')

  try {
    let result1: any, result2: any
    result1 = await createToken(
      {
        //MerchantID: MerchantID,
        value: card,
        AuditData: 'Added from widget',
        ...(expiry_date && {ExpiryDate: getExpiryString(expiry_year, expiry_month)}),
      },
      sessionKeys
    )
    if (result1.status === 200) {
      result2 = await createToken(
        {
          //MerchantID: MerchantID,
          value: cvc,
          AuditData: 'Added from widget',
          ...(expiry_date && {ExpiryDate: getExpiryString(expiry_year, expiry_month)}),
        },
        sessionKeys
      )
    }

    /*
      HANDLE ERRORS
      */
    if (
      (result1 && result1.status && result1.status !== 200) ||
      (result2 && result2.status && result2.status !== 200)
    ) {
      //SHOW ERROR IF NOT CLEAN RESULT
      let msg = `Response Error ${result1.status} / ${result2 && result2.status ? result2.status : ''}`
      console.log('## Widget Error - Error creating Token', result1, '-->', result2)

      //setError(msg)
      handleError({
        error: true,
        message: msg,
        status: result2.status || result1.status,
      })
      return
    }

    let card_token = _get(result1, 'data.data.Token', '')
    let cvc_token = _get(result2, 'data.data.Token', '')
    let card_mask = _get(result1, 'data.data.Mask', '')
    let stripCard = String(card_number).replaceAll(' ', '')
    let tailChars = stripCard.substring(0, 1) === '3' ? 3 : 4
    let card_partial = `${stripCard.substring(0, 4)} ${stripCard.substring(4, 6)}** **** ${stripCard.substring(
      stripCard.length - tailChars
    )}`

    let id = ''
    let ref = ''
    let success = false
    if (mode === 'CAPTURE') {
      /* 
      FOR CAPTURE MODE - RETURN ONLY THE TOKENISED VALUES
      */
      ref = 'CAPTURE'
      id = 'CAPTURE'
      success = true
    } else {
      /*
      WHEN NOT IN CAPTURE MODE - SEND TO PAYMENT GATEWAY
      STEP 2 - SEMD PAYMENT TOKENS TO PAYMENT GATEWAY (WHICH WILL FORWARD PAYMENT TO PAYMENT PROVIDER)
    */
      let postObj = {
        method: 'POST',
        url: `${sessionKeys.paygate_url}/api/session/payment`,
        headers: {
          'x-api-key': `${sessionKeys.session1_token}`,
        },
        data: {
          mode,
          MerchantID,
          card_mask,
          card_token,
          cvc_token,
          expiry_year,
          expiry_month,
          cardholder,
          amount: mode !== 'CAPTURE' ? amount : 0, //For Capture (save) amount is always zero
          checkin_date,
          checkout_date,
          reference,
          reason,
          TransactionType,
          card_type: getCardTypeEnum(card_number),
        },
      }
      console.log('## Widgget - POST OBJ', postObj)
      //console.log('## Widget POST PAYLOAD', postObj)
      let postResult = await axios(postObj)
      //console.log('## Widget POST RESULT', postResult)

      /*
      STEP 3 - RETURN RESULT TO PARENT OF IFRAME
    */

      let error = _get(postResult, 'data.error', true)
      //let success = _get(postResult, 'data.success', false)
      success = !error
      id = _get(postResult, 'data.providerResponse.id', _get(postResult, 'data.id', '???'))
      ref = _get(postResult, 'data.ref', '???')
    }

    let result = {
      message: 'PAYRESULT',
      mode,
      card_token,
      cvc_token,
      cardholder,
      expiry_year,
      expiry_month,
      expiry: `${expiry_month}/${expiry_year.substring(2, 4)}`,
      amount: mode !== 'CAPTURE' ? amount : 0, //For Capture (save) amount is always zero
      ref,
      success: success,
      error: !success,
      card_mask,
      MerchantID,
      card_type: getCardTypeEnum(card_number),
      card_partial,
      reference,
      reason,
    }

    if (!ref || ref === '???') {
      //console.log('## Widget - Error Processing Payment', result)
      // eslint-disable-next-line
      throw {
        error: true,
        message: `Expected reference was returned`,
        data: result,
        status: 999,
      }
    }

    setSubmitted(_get(result, 'ref', false))

    //WE HAVE RESULT - SEND TOKENS TO PARENT
    window.parent.postMessage(
      {
        ...result,
        id,
        MerchantID,
        //2022-04-10 INCLUDE CARDHOLDER AND EXPIRY
        // id,
        // card_token,
        // cvc_token,
        // ref,
        // mode,
        // message: 'PAYRESULT',
        // amount,
      },
      '*'
    )
  } catch (e) {
    console.log('## Widget@Widget Received Error Post', e)
    //2022-11017 Change to look for nested respose.data first (being result from vault)
    window.parent.postMessage(
      {
        error: true,
        message: 'PAYRESULT',
        status: _get(e, 'response.data.response.status', _get(e, 'response.status', '?')),
        statusText: _get(e, 'response.data.response.statusText', _get(e, 'response.statusText', '')),
        details: _get(e, 'response.data.message', _get(e, 'message', 'Failed!')),
        merchant: MerchantID,
      },
      '*'
    )
    return handleError(e)
  }
}
