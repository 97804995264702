import React, {Fragment, useState} from 'react'
import {Box, Text} from '@chakra-ui/react'
import {useEffect} from 'react'

export enum CardErrorTypes {
  SESSION_KEYS,
  MERCHANT_ID,
  DATE,
}
export interface ICardEntryErrors {
  type?: CardErrorTypes
  message?: string
}

export interface ICardEntryErrors {
  type?: CardErrorTypes
  message?: string
}

interface IProps {
  errors: {[key in CardErrorTypes]: string} | any
  setResize: any
}

export const CardErrors = (props: IProps) => {
  let {errors, setResize} = props
  let [lastError, setLastError] = useState(null)

  // RESIZE AFTER RENDER WHEN THERE ARE ERRORS
  useEffect(() => {
    if (lastError !== errors) {
      setLastError(errors)
      setResize(true)
    }
  }, [errors, setLastError, setResize, lastError])

  const hasErrors = Object.keys(errors).length > 0;
  //if (hasErrors) {
    //return (
      //<Box bg="transparent" minHeight="200px" h="100%" borderRadius="8px" my={2} p={2}>
        //<Text bg="transparent" w="100%" color="red.400" fontSize="22px" textAlign="center">
          //<Text mb={'10px'}>Card Error!</Text>
          //{Object.keys(errors).map((k, i) => (<Fragment key={i}>{errors[k].message}</Fragment>))}
        //</Text>
      //</Box>
    //)
  //}

  return <></>;
}
