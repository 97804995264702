import axios from 'axios'
import _get from 'lodash/get'

export const processClaim = async (args: any) => {
  let {handleError, sessionKeys, setSubmitted, MerchantID, mode, reference} = args
  try {
    let postObj = {
      method: 'POST',
      url: `${sessionKeys.paygate_url}/api/session/claim`,
      headers: {
        'x-api-key': `${sessionKeys.session1_token}`,
      },
      data: args,
    }

    let result = await axios(postObj)
    setSubmitted(_get(result, 'ref', false))
    //WE HAVE RESULT - SEND TOKENS TO PARENT

    //console.log('## Widget Claim Result', result)

    window.parent.postMessage(
      {
        merchant: MerchantID,
        message: 'PAYRESULT',
        ..._get(result, 'data.providerResponse'),

        mode,
        reference,
      },
      '*'
    )
  } catch (e) {
    //console.log('## Widget ERROR IN CLAIM', e)
    window.parent.postMessage(
      {
        error: true,
        message: 'PAYRESULT',
        details: _get(e, 'response.data.message', _get(e, 'message', 'Failed!')),
        mode: mode,
        reference,
      },
      '*'
    )

    return handleError(e)
  }
}
