import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import { Test, CardEntry } from './Widgets'
import Demo from './Demo'

import './App.css'

const App = () => {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/test' element={<Test />} />
          <Route path='/cardentry' element={<CardEntry />} />
          <Route path='/demo' element={<Demo />} />
          <Route path='' element={<Navigate to='/cardentry' />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
};

export default App
