import React, { useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

interface IProps {
  //errors: { [key in CardErrorTypes]: string } | any
  setResize: any
  okMessage: ReactJSXElement | string
  errorMessage: ReactJSXElement | string
}

export const CardResultNotice = (props: IProps) => {
  let { errorMessage, setResize } = props

  // RESIZE AFTER RENDER WHEN THERE ARE ERRORS
  useEffect(() => {
    setResize(true)
  }, [setResize])

  return (
    <Box bg='red.100' borderRadius='8px' my={2} p={2}>
      {errorMessage}
    </Box>
  )
}
