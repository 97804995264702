import React, { useEffect, useState } from 'react'
import { Box, Flex, Button } from '@chakra-ui/react'

export const Test = () => {
  // eslint-disable-next-line
  const [message, setMessage] = useState('Message from Parent not yet received')
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false)
    function receiveMessage(event) {
      // document.getElementById('results').innerHTML = event.data
      if (typeof event.data === 'string') {
        setMessage(event.data)
      }

      //alert('got message: ' + event.data)
    }
  }, [])

  const sendMessage = () => {
    window.parent.postMessage({ message: 'MESAGE FROM IFRAME' }, '*')
  }

  return (
    <Box>
      <Flex direction='column'>
        <Box fontSize={24} fontWeight={700}>
          THIS IS A TEST WIDGET
        </Box>
        <Button
          variant='outline'
          colorScheme='primary'
          onClick={() => {
            sendMessage()
          }}
        >
          POST MESSAGE TO PARENT
        </Button>
        <div id='results'>{message}</div>
      </Flex>
    </Box>
  )
}
