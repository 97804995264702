import axios from 'axios'
import _get from 'lodash/get'

export const getCardDetails = async (args: any) => {
  try {
    let {sessionKeys, cardToken, cvcToken} = args
    let obj = {
      method: 'GET',
      url: `${sessionKeys.vault_url}/tokens/token/${cardToken}`,
      headers: {
        'X-API-KEY': `${sessionKeys.session2_token}`,
      },
    }
    let result1 = await axios(obj)
    obj.url = `${sessionKeys.vault_url}/tokens/token/${cvcToken}`
    let result2 = await axios(obj)
    return [_get(result1, 'data.data.Value', null), _get(result2, 'data.data.Value', null)]
  } catch (e) {
    throw e
  }
}
