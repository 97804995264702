import React from 'react'
import { Center, Box } from '@chakra-ui/react'
import { CardEntry } from './Widgets'

const Demo = () => {
  return (
    <Center bg='gray.100' h='100vh'>
      <Box m={5} p={3} bg='white' borderRadius='8px'>
        <Box w={1000} h={550}>
          <CardEntry />
        </Box>
      </Box>
    </Center>
  )
}
export default Demo
