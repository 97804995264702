import { extendTheme } from '@chakra-ui/react';

import { fontSizes, textTheme } from './components';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        userSelect: 'none',
      },
    },
  },
  fontSizes,
  fonts: {
    heading: 'Public Sans',
    body: 'Public Sans',
  },
  components: {
    Text: {
      ...textTheme,
    },
  }
});

export default theme;
